import { RouterLink } from "@angular/router";

export const Environment = {
  API_PATH: '/api/v1/',
  TITLE: 'Fluidilogix',
  LOGO: '/assets/logo.png',
  LAYOUT_MENU: [
    {
      separator: true,
    },
    {
      label: 'DÉPÔTS',
      items: [{ label: 'Dépôts', routerLink: 'depots' }],
    },
    {
      label: 'OPÉRATIONS',
      items: [
        { label: 'Entrée des emballés', routerLink: 'operations/emballes' },
        { label: 'Retour des clients', routerLink: 'operations/retour-client' },
        { label: 'Inventaires', routerLink: 'operations/inventaires' },
        { label: 'Remplissage des fûts', routerLink: 'operations/remplissage' },
        { label: 'Opération de purge', routerLink: 'operations/purge' },
        { label: 'Opération de reclassement', routerLink: 'operations/reclassement' },
        { label: 'Opération de reformage', routerLink: 'operations/reformage' },
        { label: 'Enlèvements', routerLink: 'operations/enlevements' },
      ],
    },
    {
      label: 'CODES-BARRES',
      items: [{ label: 'Génération de codes-barres', routerLink: 'codes-barres/lots' }],
    },
    {
      label: 'COMMANDE',
      items : [
        {label: 'Nouvel ordre d\'enlèvement', routerLink: 'commande/nouvel-ordre-enlevement'},
      ],
    },
  ],
  LAYOUT_FOOTER_MENU: [
    {
      label: 'Utilisateurs',
      routerLink: 'utilisateurs',
      icon: 'pi pi-users',
    },
    {
      label: 'Référentiels',
      routerLink: 'referentiels',
      icon: 'pi pi-sliders-h',
    },
  ],
}
